// bg-*
// =========================================================================
section {
	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section + .content-section,
.lead-section + .bundle-detail-section,
.lead-section + .bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// home intro section
.home-intro-section {
	padding: 9vh 0;
	@include media-breakpoint-down(lg) {
		padding: 5vh 0;
	}
	.container {
		&.intro {
			margin-bottom: 6vh;
			.column {
				max-width: 75%;
				margin: auto;
				@include media-breakpoint-down(lg) {
					max-width: 100%;
				}
				.wysiwyg {
					p {
						font-size: 18px;
						font-style: normal;
						font-weight: 400;
					}
					.btn {
						margin-left: auto;
					}
				}
			}
		}
		&.content {
			.column.one {
				padding-right: 1rem !important;
				position: relative;
				z-index: 990;
				.circle-link {
					a {
						position: absolute;
						top: 0;
						right: -1rem;
						transform: translate(55%, 6vh) rotate(-8deg);
						text-align: center;
						background: $orange;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #fff;
						font-family: $font-family-secondary;
						font-size: 20px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						border-radius: 50%;
						aspect-ratio: 1/1;
						width: 160px;
						max-width: 160px;
						padding: 15px;

						text-decoration: none;
						z-index: 990;
						transition: 0.35s ease;
						@include media-breakpoint-down(lg) {
							top: unset;
							bottom: 0;
							right: unset;
							left: 50%;
							transform: translate(-50%, 75%);
						}
						&:hover {
							transform: translate(55%, 6vh) rotate(0deg)
								scale(1.15);
							transition: 0.35s ease;
						}
					}
				}
			}
			.column.two {
				padding-top: 9vh;
				padding-left: 1rem !important;
			}
			.owl-dots {
				display: none;
			}
			.owl-nav {
				button {
					width: unset;
					transform: translateY(25%);
					i::before {
						content: "\f0a9";
						font-size: 24px;
						font-style: normal;
						font-weight: 300;
					}
					&.owl-prev {
						left: unset;
						right: 3.5rem;
						i::before {
							content: "\f0a8";
						}
					}
					&.owl-next {
						right: 1.5rem;
					}
				}
			}

			.card {
				border-radius: 10px;
				border: 0;
				box-shadow: none;
				position: relative;

				.card-body {
					position: relative;

					.card-title {
						position: absolute;
						top: 0;
						left: 1rem;
						transform: translateY(calc(-100% - 1rem));
						color: #fff;
						font-family: $font-family-secondary;
						font-size: 54px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						max-width: 90%;
					}

					.card-description {
						color: $brown-dark;
						font-size: 20px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						
					}
					.card-btn {
						padding: 0;
						border: 0;
						position: relative;
						max-width: 100px;
						max-height: 38px;
						overflow: hidden;
						display: flex;
						align-items: center;
						justify-content: center;
						color: $white;
						margin-left: auto;
						background: $white;
						min-height: 38px;
						&::after {
							content: "\f0a9";
							font-family: $font-awesome;
							background: $white;
							color: $brown-dark;
							position: absolute;
							font-size: 36px;
							font-style: normal;
							font-weight: 300;
							line-height: normal;
						}
						&:hover {
							&::after {
								color: $orange;
							}
						}
					}
				}
			}
		}
	}
}
// home usp section
.home-usp-section {
	overflow: hidden;
	.container {
		&.intro {
			margin-bottom: 6vh;
			.column {
				max-width: 75%;
				margin: auto;
				@include media-breakpoint-down(lg) {
					max-width: 100%;
				}
				.wysiwyg {
					p {
						font-size: 18px;
						font-style: normal;
						font-weight: 400;
					}
					a {
						color: $white !important;
					}
					.btn {
						margin-left: auto;
					}
				}
			}
		}
	}
	.slider {
		padding: 9vh 0;
		@include media-breakpoint-down(lg) {
			padding: 4vh 0 7vh 0;
		}
		.owl-nav {
			display: flex;
			justify-content: center;
			margin-top: 0.5rem;
			button {
				position: relative;
				transform: translateY(9vh);
				left: unset !important;
				right: unset !important;
				i::before {
					content: "\f0a9";
					font-weight: 300;
					font-size: 36px;
				}
				&.owl-prev {
					i::before {
						content: "\f0a8";
					}
				}
			}
		}
		.owl-stage-outer {
			overflow: visible;
		}
		.owl-item {
			&:nth-child(2n) {
				transform: translateY(-9vh);
				@include media-breakpoint-down(lg) {
					transform: translateY(-4vh);
				}
			}
			&:nth-child(3n + 1) {
				transform: translateY(9vh);
				@include media-breakpoint-down(lg) {
					transform: translateY(4vh);
				}
			}
			.card {
				@extend .default-card;
				background: $grey;
				height: unset !important;
				.card-body {
					padding-bottom: 4rem;
				}
			}
		}
	}
}

// home-content-section
.home-content-section {
	padding: 6vh 0;
	.container {
		.one {
			max-width: 45% !important;
			flex-basis: 45% !important;
			justify-content: center;

			@include media-breakpoint-down(lg) {
				max-width: 85% !important;
				flex-basis: 85% !important;
				margin: auto;
				order: 1;
				justify-content: flex-start;
			}
		}
		.two {
			max-width: 55% !important;
			flex-basis: 55% !important;
			@include media-breakpoint-down(lg) {
				max-width: 100% !important;
				flex-basis: 100% !important;
			}
		}
		.content-carousel {
			.owl-dots {
				display: none;
			}
			.owl-nav {
				display: flex;
				transform: translateY(-101%);
				justify-content: flex-end;
				gap: 0.35rem;
				padding-right: 1rem;

				button {
					position: relative;
					transform: none;
					left: 0 !important;
					right: 0 !important;
					width: unset;
					i::before {
						content: "\f0a9";
						font-weight: 300;
						color: #fff;
						font-family: "Font Awesome 5 Pro";
						font-size: 24px;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
					}
					&.owl-prev {
						i::before {
							content: "\f0a8";
						}
					}
				}
			}
			.owl-item,
			.item {
				border-radius: 10px;
				min-height: 40vh;
				@include media-breakpoint-down(lg) {
					min-height: 30vh;
					max-height: 30vh;
				}
			}
		}
		&.reversed {
			margin-top: 6vh;
			.one {
				max-width: 55% !important;
				flex-basis: 55% !important;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
					order: -1;
				}
			}
			.two {
				max-width: 45% !important;
				flex-basis: 45% !important;
				justify-content: center;
				@include media-breakpoint-down(lg) {
					max-width: 85% !important;
					flex-basis: 85% !important;
					justify-content: flex-start;
					margin: auto;
				}
			}
		}
	}
}

// home outro
.home-outro-section {
	padding-top: 0 !important;
	.owl-carousel {
		.owl-dots {
			display: none;
		}
		.owl-nav {
			display: flex;
			justify-content: flex-end;
			transform: translateY(-100%);
			padding-right: 2rem;
			padding-bottom: 1.5rem;
			gap: 0.75rem;
			@include media-breakpoint-down(lg) {
				padding-bottom: 0.5rem;
			}
			button {
				position: relative;
				left: 0 !important;
				right: 0 !important;
				width: unset;
				i::before {
					content: "\f0a9";
					font-weight: 300;
					color: #fff;
					font-family: "Font Awesome 5 Pro";
					font-size: 36px;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
				}
				&.owl-prev {
					i::before {
						content: "\f0a8";
					}
				}
			}
		}
		.owl-item {
			min-height: 75vh;
			@include media-breakpoint-down(lg) {
				min-height: 35vh;
			}
			.item {
				padding: 0;
			}
			.owl-container {
				max-width: 100% !important;
			}
			.owl-caption-holder {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: flex-end;
				justify-content: flex-start;
				.owl-caption {
					display: flex;
					flex-flow: column-reverse;
					text-align: left;
					justify-content: flex-end;
					align-items: flex-start;
					margin-bottom: 1.5rem;
					.owl-description,
					.owl-btn {
						display: none;
					}
					.owl-title {
						color: $white;
						font-family: $font-family-secondary;
						font-size: 90px;
						font-style: normal;
						font-weight: 400;
						@include media-breakpoint-down(lg) {
							font-size: 40px;
						}
					}
					.owl-subtitle {
						color: $white;
						text-align: center;
						font-family: $font-family-primary;
						font-size: 24px;
						font-style: normal;
						font-weight: 400;
						margin-bottom: -0.5rem;
						text-transform: uppercase;
						@include media-breakpoint-down(lg) {
							font-size: 16px;
						}
					}
				}
			}
		}
	}
	.container {
		.owl-nav {
			display: flex;
			justify-content: center;
			transform: none;
			padding-right: 0rem;
			padding-bottom: 0rem;
			gap: 0.75rem;
			margin-top: 6vh;
			@include media-breakpoint-down(lg) {
				padding-bottom: 0.5rem;
			}
			button {
				position: relative;
				left: 0 !important;
				right: 0 !important;
				width: unset;
				transform: none;
				i::before {
					content: "\f0a9";
					font-weight: 300;
					color: #fff;
					font-family: "Font Awesome 5 Pro";
					font-size: 36px;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
				}
				&.owl-prev {
					i::before {
						content: "\f0a8";
					}
				}
			}
		}
		.owl-item {
			min-height: initial !important;
		}
		&.intro {
			margin-bottom: 6vh;
			margin-top: 6vh;
			.column {
				max-width: 75%;
				margin: auto;
				@include media-breakpoint-down(lg) {
					max-width: 100%;
				}

				.wysiwyg {
					p {
						font-size: 18px;
						font-style: normal;
						font-weight: 400;
					}
					a {
						color: $white !important;
					}
					.btn {
						margin-left: auto;
					}
				}
			}
		}
		&.cards {
			.card {
				position: relative;
				border-radius: 10px;
				border: 0;
				box-shadow: none;
				.card-body {
					.card-subtitle {
						position: absolute;
						top: 1rem;
						left: 1rem;
						color: #fff;
						font-family: $font-family-primary;
						font-size: 14px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						background: $orange;
						border-radius: 4px;
						padding: 10px;
						&::before {
							content: url("/images/talk.svg");
							position: absolute;
							left: 0;
							bottom: 0;
							transform: translate(7px, 90%);
						}
					}
					.card-title {
						margin-bottom: 0;
					}
					.card-description {
						display: flex;
						flex-flow: column;
						.list.list-icons {
							order: -1;
							margin-top: 0.35rem;
							margin-bottom: 2.5rem;
							display: flex;
							gap: 0.45rem;

							display: none !important;
							// quick fix
							&:nth-child(3){
								display: flex!important;
							}

							li {
								padding: 4px 8px;
								border-radius: 35px;
								background: $green;
								display: flex !important;
								align-items: center;
								gap: 0.35rem;
								.label,
								.title {
									font-size: 13px;
									color: $white;
									font-weight: 200;
								}
								.icon {
									color: #fff;
									font-size: 14px;
									font-style: normal;
									font-weight: 300;
									line-height: normal;
									position: relative !important;
									i::before {
										font-weight: 200;
									}
									i.fa-bed::before {
										content: "\f8f7";
									}
								}

								&:hover {
									.hover-label {
										bottom: calc(100% + 1em);
										z-index: 1;
										visibility: visible;
										opacity: 1;
									}
								}

								.hover-label {
									position: absolute;
									bottom: 100%;
									left: 50%;
									width: max-content;
									transform: translateX(-50%);
									background-color: $black;
									color: #fff;
									padding: 4px 15px;
									border-radius: 4px;
									filter: drop-shadow(
										2px 2px 2px rgba(#000, 0.4)
									);
									z-index: -1;
									visibility: hidden;
									opacity: 0;
									transition: 0.2s;
									font-size: $small-font-size;
									text-transform: capitalize;

									@include media-breakpoint-down(lg) {
										max-width: 110px;
										padding: 4px 10px;
										text-overflow: ellipsis;
										overflow-x: clip;
									}

									&::after {
										content: "";
										display: block;
										position: absolute;
										bottom: -8px;
										left: 50%;
										transform: translateX(-50%);
										border-left: 10px solid transparent;
										border-top: 10px solid $black;
										border-right: 10px solid transparent;
									}
								}
							}
						}
					}
				}
				.card-btn {
					margin-left: auto;
					font-size: 14px;
					i {
						font-size: 24px;
					}
				}
			}
		}
	}
}
// lead-section
// =========================================================================
&:not(.accommodation-detail) {
	.lead-section {
		.container-one-column {
			.container-holder {
				max-width: 750px;
			}
		}
	}
}
.lead-section {
	margin: 6vh 0;

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
&.bundle-overview, &.service-overview {
	.bundle-detail-section{
		display: none;
	}
	.lead-section{
		.container-holder{
			max-width: 100%!important;
		}
	}
	.bundle-overview-section, .lead-section {
		.title {
			max-width: 50%;
		}
		.wysiwyg {
			max-width: 40%;
			@include media-breakpoint-down(lg){
				max-width: 100%;
			}
		}
		.grid-items {
			justify-content: space-between;
			.item {
				max-width: 50% !important;
				flex-basis: 50% !important;
				max-height: max-content;
				@include media-breakpoint-down(lg) {
					max-width: 100% !important;
					flex-basis: 100% !important;
				}
				.card {
					height: unset !important;
					.card-title {
						color: #fff;
						font-family: Alice;
						font-size: 64px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						@include media-breakpoint-down(lg) {
							font-size: 32px;
						}
					}
					.card-subtitle {
						display: none;
					}
					.card-description {
						color: #575741;
						font-family: "Red Hat Display";
						font-size: 20px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						
						@include media-breakpoint-down(lg) {
							font-size: 16px;
						}
					}
					.card-btn {
						margin-left: auto;
						position: relative;
						max-width: 3rem;
						color: $white !important;
						&::after {
							position: absolute;
							right: 0;
							top: 0;
							color: #575741;
							background: #fff;
							width: 100%;
							height: 100%;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 36px;
							font-style: normal;
							font-weight: 300;
							line-height: normal;
						}
					}
				}
			}
		}
	}
}
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		.card {
			@extend .default-card !optional;
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin-top: calc(-6vh + 30px);
	margin-bottom: calc(-6vh + 30px);
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

// contact-section
// =========================================================================
.contact-section {
	margin: 6vh 0;
}
