// container
.container {
	@media (min-width: 1280px) {
		max-width: 1210px;
	}

	&.container-small {
		@include media-breakpoint-up(sm) {
			max-width: 540px;
		}
	}

	&.container-medium {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}
	}

	&.container-large {
		@include media-breakpoint-up(lg) {
			max-width: 960px;
		}
	}

	&.container-intro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-bottom: 30px;
		text-align: center;
	}

	&.container-outro {
		@include media-breakpoint-up(md) {
			max-width: 720px;
		}

		margin-top: 30px;
		text-align: center;
	}
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// owl-carousel navs
.owl-carousel {
	.owl-nav {
		.owl-prev,
		.owl-next {
			font-size: 1.5rem;
		}
	}
}

// default-card
.default-card {
	border-radius: 10px;
	background: $white;
	border: 0;
	box-shadow: none;
	.card-body {
		position: relative;
		.card-title {
			color: $white;
			font-family: $font-family-secondary;
			font-size: 32px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			position: absolute;
			top: -1rem;
			left: 1rem;
			transform: translateY(-100%);
			padding-right: 1rem;
		}
		.card-subtitle {
			font-family: $font-family-primary;
			text-transform: uppercase;
		}
		.card-description {
			color: $brown-dark;
			font-family: $font-family-primary;
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
		}
	}
	.card-btn {
		padding: 0;
		background: transparent;
		@extend.btn, .btn-text-only;
		color: $green !important;
		font-size: 14px!important;
		margin-left: auto;
					i {
						font-size: 24px!important;
					}
	}
}

// card assortiment
.card-assortiment {
	position: relative;
	.card-image-caption {
		position: absolute;
		top: 1rem;
		left: 1rem!important;
		right: unset !important;
		bottom: unset !important;
		.card-image-label {
			color: #fff;
			font-family: $font-family-primary;
			font-size: 14px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			background: $orange;
			border-radius: 4px;
			padding: 10px;

			&::before {
				content: url("/images/talk.svg");
				position: absolute;
				left: 0;
				bottom: 0;
				transform: translate(7px, 90%);
			}
		}
	}
}
// card fix
.card{
	.card-description{
		.list-icons{
			&:nth-child(3){
				display:none!important
			}
		}
	}
}


// heart
p.heart {
	font-family: $font-family-special;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	display: flex;
	align-items: center;
	gap: 0.35rem;
	&::before {
		content: "\f004";
		font-family: $font-awesome;
		transform: rotate(-8deg);
		font-size: 24px;
		font-style: normal;
		font-weight: 900;
		color: #d99767;
	}
}

// images
.gallery-img,
.gallery-link {
	border-radius: 10px;
}
