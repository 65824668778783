// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $body-bg;
	transition: 0.5s;
	&:has(.navbar-toggler[aria-expanded="true"]) {
		background: $brown-dark;
		.logo,
		.news-ticker {
			opacity: 0;
			transition: 0.35s ease;
		}
		.site-switcher .nav-item {
			&::before {
				color: $white;
			}
			.nav-link {
				color: $white;
				transition: 0.35s ease;
			}
		}
		@include media-breakpoint-down(lg) {
			div.social-menu,
			div.contact-menu {
				display: block;
				margin: auto;
				width: 100%;
				margin-bottom: 0.35rem;
				ul {
					display: flex;
					flex-flow: column;
					gap: 0.35rem;
					justify-content: center;
					align-items: center;
					li {
						color: #ddd5ca;
						font-size: 14px;
						font-style: normal;
						font-weight: 500;
						text-transform: uppercase;
						a {
							display: flex;
							gap: 0.3rem;
							align-items: center;
							color: #ddd5ca;
							font-size: 14px;
							font-style: normal;
							font-weight: 500;
							text-transform: uppercase;
							text-decoration: none;
						}
						i {
							font-weight: 400;
						}
					}
				}
			}
			div.social-menu {
				order: 5;
				margin-bottom: 3vh;
			}
			div.contact-menu {
				order: 4;
			}
		}
	}
	&.sticky {
		box-shadow: $shadow;
		.logo {
			width: 50px;
			transition: 0.35s ease;
		}
		.site-switcher {
			top: -1px;
			transition: 0.35s ease;
		}
	}

	.container,
	.container-fluid {
		.container-holder {
			> .column {
				align-items: flex-end;
				padding-bottom: 10px;
				padding-left: 2rem;
				padding-right: 2rem;
				@include media-breakpoint-down(lg) {
					padding-left: 1rem;
					padding-right: 1rem;
					padding-bottom: 5px;
				}
				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
		&.news-ticker {
			--news-ticker-speed: 30s;
			@keyframes scrollNews {
				to {
					transform: translateX(-100%);
				}
			}
			background: $white;
			padding: 0;
			overflow: hidden;
			.column {
				padding: 10px 0;
			}
			div.news-ticker {
				min-width: 100%;
				display: flex;
				gap: 3rem;
				flex-wrap: nowrap;
				justify-content: flex-start;
				&:not(:has(.active)) {
					justify-content: center;
				}
				ul.news-ticker {
					display: flex;
					flex-flow: row nowrap !important;
					flex-wrap: nowrap !important;
					gap: 3rem;
					justify-content: center;
					li,
					a {
						color: $brown-dark;
						font-family: $font-family-primary;
						font-size: 14px;
						font-style: normal;
						font-weight: 400;
						line-height: normal;
						text-decoration: none;
						white-space: nowrap;

						.list-item-title {
							font-weight: 700;
							margin-right: 1ch;
						}
					}

					&.active {
						justify-content: space-between;
						animation: scrollNews var(--news-ticker-speed) linear infinite;
					}
				}
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-down(lg) {
			order: 2;
			margin-left: auto;
			margin-right: 0.5rem;
			.navbar-toggler {
				border-radius: 3px;
				background: $green;
				width: 32px;
				aspect-ratio: 1/1;
				padding: 3px;
				display: flex;
				justify-content: center;
				align-items: center;
				i {
					color: $white;
					transition: 0.35s ease;
					&::before {
						font-size: 18px;
						color: $white;
					}
				}
				&[aria-expanded="true"] {
					i {
						transform: rotate(360deg);
						transition: 0.35s ease;
						&::before {
							content: "\f00d";
						}
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 87px;
		margin: 10px 10px 10px 0;
		transition: 0.35s ease;
		a {
			@extend .d-block;

			img {
				@extend .w-100;
			}
		}
		@include media-breakpoint-down(lg) {
			width: 74px;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 3;
			.navbar-nav {
				max-width: 90%;
				margin: 6vh auto;
				gap: 0.5rem;
				.nav-item {
					border-bottom: 1px solid $white;
					&:nth-last-child(1) {
						border-bottom: 0;
					}
					.nav-link {
						color: #fff;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						text-transform: uppercase;
					}
					&.dropdown > .nav-link {
						display: flex;
						justify-content: space-between;
						align-items: center;
						.caret {
							display: none;
						}
						&::after {
							content: "\f0ab";
							color: #fff;
							font-family: $font-awesome;
							font-size: 16px;
							font-style: normal;
							font-weight: 300;
							line-height: normal;
							border: 0;
							transition: 0.35s ease;
						}
					}
					&.show {
						> .nav-link {
							color: $orange;
							&::after {
								transform: scaleY(-1);
								color: $orange;
								transition: 0.35s ease;
							}
						}
					}
					.dropdown-menu.show {
						position: static !important;
						border: 0 !important;
						background: none !important;
						transform: none !important;
						padding: 0.25rem 0 2.5rem 0 !important;

						.nav-item {
							border-bottom: 0;
							.nav-link {
								font-weight: 400;
								text-transform: none;
							}
							&.active {
								.nav-link {
									color: $orange;
								}
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;
				margin-right: 1.5rem;
				gap: 1.5rem;
				.nav-item {
					.nav-link {
						color: $brown-dark;
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
					}
					&.active,
					&:hover {
						.nav-link {
							color: $green;
							text-decoration: 2px underline;
							text-underline-offset: 7px;
						}
					}
					.dropdown-menu.show {
						background: $body-bg;
						border-radius: 0 0 10px 10px;
						min-width: 10rem;
						text-align: center;
						box-shadow: none;
						border: 0;
						&::before,
						&::after {
							display: none;
						}
						.nav-item {
							.nav-link {
								text-decoration: none;
								color: $brown-dark;
							}
							&:hover > .nav-link{
								color: $green;
								text-decoration: 2px underline;
							}
						}
					}
				}
			}
		}
	}

	// menu vocabulary-term-lists
	div.social-menu,
	div.contact-menu {
		display: none;
	}

	// book button
	.book-button {
		a {
			@extend .btn, .btn-primary;
		}
		@include media-breakpoint-down(lg) {
			order: 2;
			a {
			}
		}
	}
	// site-switcher
	.site-switcher {
		position: absolute;
		top: 15px;
		right: 2.2rem;
		@include media-breakpoint-down(lg) {
			right: 1rem;
		}

		.nav-item {
			box-shadow: none !important;
			display: flex;
			margin-left: 0;
			&::before {
				content: "/";
				margin: auto 0.15rem;
				display: inline;
				font-size: 12px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				float: left;
			}
			&:nth-child(1) {
				&::before {
					display: none;
				}
			}
			.nav-link {
				color: $brown-dark;
				font-family: "Red Hat Display";
				font-size: 12px;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
				box-shadow: none !important;
			}
			&.active,
			&:hover {
				.nav-link {
					font-weight: 500;
				}
			}
		}
	}
}
