// override variables or add custom variables
// source: https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss

// colors
$brown: #766350;
$brown-dark: #575741;
$green: #9d9c5d;
$grey-dark: #978779;
$grey: #ddd5ca;
$orange: #d99767;
$white: #fff;
$black: #000;

// theme-colors
$primary: $orange;
$secondary: $green;

// body
$body-bg: #E8E8E2;
$body-color: $brown-dark;



// links
$link-color: $green;
$link-decoration: underline;
$link-hover-color: darken($link-color, 50%);

// typography
@import url('https://fonts.googleapis.com/css2?family=Alice&family=Caveat:wght@400..700&family=Red+Hat+Display:ital,wght@0,300..900;1,300..900&display=swap');
$font-family-primary: "Red Hat Display", serif;
$font-family-secondary: "Alice", serif;
$font-family-special: "Caveat", serif;

$font-family-base: $font-family-primary;

$font-size-base: 1.125rem; // 18px

$h1-font-size: $font-size-base * 2.66667; // 48px
$h2-font-size: $font-size-base * 1.77778; // 32px
$h3-font-size: $font-size-base * 1.55556; // 28px
$h4-font-size: $font-size-base * 1.33333; // 24px
$h5-font-size: $font-size-base * 1.11111; // 20px
$h6-font-size: $font-size-base; // 18px

$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-color: $brown;

// contextual
$light: $green;
$dark: $brown-dark;
$text-light: $white;
$text-dark: $white;

// matrix-colors
$matrix-color-primary: $primary;
$matrix-color-secondary: $secondary;

// shadow
$shadow: none;

/*
// carousel
$carousel-minheight: 35vh;
$carousel-padding: 7.5vh 0;
$carousel-minheight-large: 70vh;
$carousel-padding-large: 15vh 0;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;

// grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1140px,
  xxxl: 1140px,
) !default;
*/
