// btn
.btn {
	font-weight: 700;
	border-radius: 3px;
	font-size: 16px;
	font-family: $font-family-primary;
	padding: 10px 25px;
	@include media-breakpoint-down(lg) {
		font-size: 14px;
		padding: 5px 12px;
	}
	// btn-primary
	&.btn-primary {
		color: $white !important;
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
			color: $white;
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
	&.btn-text-only {
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: uppercase;
		position: relative;
		color: $green;
		align-content: center;
		display: flex;
		align-items: center;
		max-width: fit-content;
		padding: 0;
		&::after {
			content: "\f0a9";
			font-family: $font-awesome;
			font-size: 24px;
			font-style: normal;
			font-weight: 300;
			line-height: 12px;
			color: inherit;
			margin-left: 0.5rem;
			transition: 0.35s ease;
		}
		&:hover {
			&::after {
				transform: translateX(7px);
				transition: 0.35s ease;
			}
		}
	}
}

// card-btn
.card-btn {
	@extend .btn;
	@extend .btn-text-only;
}

// btn-back
.btn-back {
	margin-bottom: 0 !important;
	position: relative;
	display: inline-flex;
	align-items: center;
	padding-left: 1rem;

	&::before {
		content: "\f0d9";
		position: absolute;
		left: 0;
		font-weight: 900;
		font-family: $font-awesome;
	}
}

// btn-down
.btn-down {
	@extend .btn;
	@extend .btn-primary;
}
