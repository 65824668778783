// footer
.footer {
	section {
		&.footer-top-section {
			position: relative;
			overflow: hidden;
			padding: 0 !important;
			.container.cta {
				margin-top: 6vh;
				.container-holder {
					max-width: 75%;
					margin: auto;
					@include media-breakpoint-down(lg){
						max-width: 100%;
						padding: 3vh 0;
						.column{
							text-align: center;
						}
					}
					.column {
						
						.btn {
							margin-left: auto;
							color: $white;
							@include media-breakpoint-down(lg){
								margin-right: auto;
							}
						}
					}
				}
			}
			.container-fluid {
				.column {
					min-height: 300px;
					position: relative;
					@include media-breakpoint-down(lg) {
					}
					.image {
						position: absolute;
						bottom: 0;
						left: 50%;
						width: 278px;
						transform: translate(-55%, 43%);
					}
					div.partner-logos {
						position: absolute;
						right: 3rem;
						bottom: 2rem;
						@include media-breakpoint-down(lg) {
							right: unset;
							bottom: unset;
							top: 2rem;
							left: 50%;
							transform: translateX(-50%);
						}
						ul {
							display: flex;
							align-items: center;
							gap: 0.75rem;
							@include media-breakpoint-down(lg) {
								flex-flow: row;
								justify-content: center;
							}
							li {
								transition: 0.35s ease;
								.list-item-title {
									display: none;
								}
								&:hover {
									transform: translateY(-3px);
									transition: 0.35s ease;
								}
							}
						}
					}
				}
			}
		}
		&.footer-bottom-section {
			padding: 1rem 0;
			.container-fluid {
				.column {
					display: flex;
					flex-flow: column;
					justify-content: center;
					align-items: center;
					.footer-text {
						text-align: center;
						color: #000;
						text-align: center;
						font-size: 16px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
						text-transform: uppercase;
						margin: 3vh auto;
					}
					div.image {
						margin-top: 0.5rem;
						img {
							width: 140px;
						}
					}
					div.social-media {
						position: absolute;
						right: 3rem;
						top: 0rem;
						@include media-breakpoint-down(lg) {
							position: relative;
							right: unset;
							top: unset;
						}
					}
					div.contact {
						position: absolute;
						top: 0;
						left: 3rem;
						@include media-breakpoint-down(lg) {
							position: relative;
							right: unset;
							top: unset;
							left: unset;
						}
					}
					div.contact,
					div.social-media {
						@include media-breakpoint-down(lg) {
							order: 2;
							margin-bottom: 0.35rem;
						}
						ul {
							display: flex;
							gap: 0.75rem;
							@include media-breakpoint-down(lg) {
								flex-flow: column;
								text-align: center;
								align-items: center;
								justify-content: center;
							}
							a {
								color: $brown-dark;
								text-decoration: none;
								font-size: 16px;
								font-style: normal;
								font-weight: 500;
								text-transform: uppercase;
								@include media-breakpoint-down(lg) {
									text-align: center;
								}
								i {
									font-size: 16px;
									font-style: normal;
									font-weight: 400;
									&::before {
										transition: 0.35s ease;
									}
								}
								&:hover {
									text-decoration: 1px underline;
									text-underline-offset: 5px;
									i::before {
										transform: translateY(-3px);
										transition: 0.35s ease;
									}
								}
							}
						}
					}
				}
			}
		}
		&.footer-socket {
			padding-bottom: 1.5rem;
			.list {
				padding: 1rem;
				display: flex;
				gap: 1rem;
				align-items: center;
				justify-content: center;
				@include media-breakpoint-down(lg) {
					flex-flow: column;
					text-align: center;
				}
				a {
					color: #000;
					text-align: center;
					font-size: 14px;
					font-style: normal;
					font-weight: 400;
					line-height: normal;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
						text-underline-offset: 5px;
					}
				}
			}
		}
	}
}
