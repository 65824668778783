// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
	.owl-title {
		color: #fff;
		font-family: $font-family-secondary;
		font-size: 90px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		@include media-breakpoint-down(lg) {
			font-size: 40px !important;
			max-width: 60%;
		}
	}
	.owl-subtitle {
		display: block !important;
		color: #fff;
		text-align: center;
		font-family: $font-family-primary;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		text-transform: uppercase;
		@include media-breakpoint-down(lg) {
			font-size: 16px;
		}
	}
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 40vh;
	
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: 70vh;
	}
}

.eyecatcher {
	.video.title,
	.video.subtitle {
		display: none;
	}
	.owl-container {
		max-width: 100% !important;
	}
	.owl-caption-holder {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: flex-end;
		justify-content: flex-start;
		.owl-caption {
			display: flex;
			flex-flow: column-reverse;
			text-align: left;
			justify-content: flex-end;
			align-items: flex-start;
			margin-bottom: 2.5rem;
			padding-left: 2rem;
			.owl-description,
			.owl-btn {
				display: none;
			}
			.owl-title {
				color: $white;
				font-family: $font-family-secondary;
				font-size: 90px;
				font-style: normal;
				font-weight: 400;
			}
			.owl-subtitle {
				color: $white;
				text-align: center;
				font-family: $font-family-primary;
				font-size: 24px;
				font-style: normal;
				font-weight: 400;
				margin-bottom: -0.5rem;
				text-transform: uppercase;
			}
		}
	}
}

// eyecatcher
.eyecatcher:has(.vimeo-video) {
	position: relative;
	overflow: hidden;

	div.title {
		display: block !important;
		z-index: 990;
		position: relative;
		top: 70%;
		left: 2.5rem;
		.title {
			display: block !important;
			color: #fff;
			font-family: $font-family-secondary;
			font-size: 90px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		.subtitle {
			display: block !important;
			color: #fff;
			font-family: $font-family-primary;
			font-size: 24px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-transform: uppercase;
			text-align: left;
			margin-bottom: 0;
		}
	}
	// vimeo-video
	.vimeo-video {
		iframe {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100vw;
			height: 60vh;
			max-width: none;
			transform: translate(-50%, -50%);

			@media (min-aspect-ratio: 16/9) {
				height: 56.25vw;
			}

			@media (max-aspect-ratio: 16/9) {
				width: 177.78vh;
			}
		}
	}
}
