// mini-sab
.mini-sab {
	z-index: 991;
	position: relative;
	@include media-breakpoint-down(lg) {
		margin-top: -3vh;
	}
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;

			border-radius: 4px;
			background: transparent;

			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -50px;
				padding-left: 30px;
				padding-right: 30px;
			}

			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */
		}
	}
}
